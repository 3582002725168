import { query } from "@/utilities/axios";
import pages from "@/assets/pages.json";
// import router from '@/router';

const state = () => ({
  language: "en",
  width: null,
  height: null,
  md: 1280,
  t_lg: 1024,
  t_md: 768,
  m_lg: 480,
  m_md: 380,
  pages: pages,
  primaryColor: "#B92A21",
  scrollTop: 0,
  showHeader: true,
  show404: false,
  languages: ["ru", "en"],
  page: null,
  currency: "EUR",
  menu: [
    {
      name: {
        ru: "Заказы и детали",
        en: "Orders and Parts",
      },
      open: false,
      icon: "orderAndParts",
      meta: {
        auth: true,
        prod: true,
        user: true,
        manufacturer: true,
        admin: true,
        expert: false,
      },
      prod: true,
      children: [
        {
          name: {
            ru: "Мои заказы",
            en: "My orders",
          },
          prod: false,
          meta: pages.outgoing_orders,
          link: "/outgoing_orders",
        },
        {
          name: {
            ru: "Входящие заказы",
            en: "Incoming orders",
          },
          meta: pages.incoming_orders,
          link: "/incoming_orders",
        },
        {
          name: {
            ru: "Детали",
            en: "Parts",
          },
          meta: pages.parts,
          link: "/parts",
        },
        {
          name: {
            ru: "Файлы",
            en: "Files",
          },
          meta: pages.files,
          link: "/files",
        },
        {
          name: {
            ru: "Счета",
            en: "Bills",
          },
          meta: pages.bills,
          link: "/bills",
        },
        {
          name: {
            ru: "Акты",
            en: "Reports",
          },
          meta: pages.reports,
          link: "/reports",
        },
        {
          name: {
            ru: "Договора",
            en: "Contracts",
          },
          meta: pages.contracts,
          link: "/contracts",
        },
        {
          name: {
            ru: "Поиск изготовителя",
            en: "Manufacturer search",
          },
          meta: pages.manufacturer_search,
          link: "/manufacturer_search",
        },
      ],
    },
    {
      name: {
        ru: "Производство",
        en: "Manufacture",
      },
      open: false,
      meta: {
        auth: true,
        prod: true,
        user: false,
        manufacturer: true,
        admin: true,
        expert: false,
      },
      icon: "manufacture",
      children: [
        {
          name: {
            ru: "Расчет стоимости",
            en: "Cost calculation",
          },
          meta: pages.cost_calculation,
          link: "/cost_calculation",
        },
        {
          name: {
            ru: "Цеха",
            en: "Workshops",
          },
          meta: pages.workshops,
          link: "/workshops",
        },
        {
          name: {
            ru: "Станки",
            en: "Machine tools",
          },
          meta: pages["machine-tools"],
          link: "/machine-tools",
        },
        {
          name: {
            ru: "Технологические процессы",
            en: "Technological processes",
          },
          meta: pages["technological-processes"],
          link: "/technological-processes",
        },
        {
          name: {
            ru: "Акты испытаний инструментов",
            en: "Tool test reports",
          },
          meta: pages.tool_test_reports,
          link: "/tool_test_reports",
        },
        {
          name: {
            ru: "Загрузка станков",
            en: "Machine loading",
          },
          meta: pages.machine_loading,
          link: "/machine_loading",
        },
        {
          name: {
            ru: "Оперативное планирование",
            en: "Operational planning",
          },
          meta: pages.operational_planning,
          link: "/operational_planning",
        },
      ],
    },
    {
      name: {
        ru: "Склад",
        en: "Warehouse",
      },
      meta: {
        auth: true,
        prod: true,
        user: false,
        manufacturer: true,
        admin: true,
        expert: false,
      },
      open: false,
      icon: "warehouse",
      children: [
        {
          name: {
            ru: "Заготовки",
            en: "Blanks",
          },
          meta: pages.blanks,
          link: "/blanks",
        },
        {
          name: {
            ru: "Приспособления",
            en: "Fixtures",
          },
          meta: pages.fixtures,
          link: "/fixtures",
        },
        {
          name: {
            ru: "Инструменты",
            en: "Tools",
          },
          meta: pages.tools,
          link: "/tools",
        },
        {
          name: {
            ru: "Измерительные инструменты",
            en: "Measuring tools",
          },
          meta: pages["measuring-tools"],
          link: "/measuring-tools",
        },
        {
          name: {
            ru: "Детали",
            en: "Parts",
          },
          meta: pages.parts_on_warehouse,
          link: "/parts_on_warehouse",
        },
      ],
    },
    {
      name: {
        ru: "Настройки",
        en: "Settings",
      },
      open: false,
      meta: {
        auth: true,
        prod: true,
        user: true,
        manufacturer: true,
        admin: true,
        expert: true,
      },
      icon: "settings",
      children: [
        {
          name: {
            ru: "Пользователи",
            en: "Users",
          },
          meta: pages.users,
          link: "/users",
        },
        {
          name: {
            ru: "Место на диске",
            en: "Memory space",
          },
          meta: pages.memory_space,
          link: "/memory_space",
        },
        {
          name: {
            ru: "Биллинг",
            en: "Billing",
          },
          meta: pages.billing,
          link: "/billing",
        },
        {
          name: {
            ru: "Профиль пользователя",
            en: "User profile",
          },
          meta: pages.user_profile,
          link: "/user_profile",
        },
        {
          name: {
            ru: "Тех поддержка",
            en: "Support",
          },
          meta: pages.support,
          link: "/support",
        },
        {
          name: {
            ru: "Ролевая модель",
            en: "User roles",
          },
          meta: pages.user_roles,
          link: "/user_roles",
        },
        {
          name: {
            ru: "Профиль компании",
            en: "Company profile",
          },
          meta: pages.company_profile,
          link: "/company_profile",
        },
      ],
    },
    {
      name: {
        ru: "Библиотека",
        en: "Library",
      },
      open: false,
      icon: "library",
      meta: {
        auth: true,
        prod: true,
        user: true,
        manufacturer: true,
        admin: true,
        expert: true,
      },
      children: [
        {
          name: {
            ru: "Материалы",
            en: "Materials",
          },
          meta: pages.materials,
          link: "/materials",
        },
        {
          name: {
            ru: "ГОСТы",
            en: "Standards",
          },
          meta: pages.standards,
          link: "/standards",
        },
        {
          name: {
            ru: "Каталог инструментов",
            en: "Tools catalogue",
          },
          meta: pages.tools_catalogue,
          link: "/tools_catalogue",
        },
        {
          name: {
            ru: "Нормативы времени",
            en: "Time standards",
          },
          meta: pages.time_standards,
          link: "/time_standards",
        },
        {
          name: {
            ru: "ОСТы",
            en: "Local standards",
          },
          meta: pages.locale_standards,
          link: "/locale_standards",
        },
      ],
    },
    {
      name: {
        ru: "i5.kit",
        en: "i5.kit",
      },
      open: false,
      icon: "i5kit",
      meta: {
        auth: true,
        prod: false,
        user: false,
        manufacturer: true,
        admin: true,
        expert: false,
      },
      children: [
        {
          name: {
            ru: "I5.KIT",
            en: "I5.KIT",
          },
          meta: pages.kit,
          link: "/kit",
        },
      ],
    },
    {
      name: {
        ru: "Интеграция",
        en: "Integration",
      },
      open: false,
      icon: "integration",
      meta: {
        auth: true,
        prod: false,
        user: false,
        manufacturer: true,
        admin: true,
        expert: false,
      },
      children: [
        {
          name: {
            ru: "Cadenas",
            en: "Cadenas",
          },
          link: "/cadenas",
          meta: pages.cadenas,
        },
      ],
    },
    {
      name: {
        ru: "Эксперты",
        en: "Experts",
      },
      icon: "experts",
      meta: {
        auth: true,
        prod: false,
        user: false,
        manufacturer: false,
        admin: true,
        expert: true,
      },
      open: false,
      children: [
        {
          name: {
            ru: "Файлы",
            en: "Files",
          },
          meta: pages.all_files,
          link: "/all_files",
        },
        {
          name: {
            ru: "Детали",
            en: "Parts",
          },
          meta: pages.all_parts,
          link: "/all_parts",
        },
        {
          name: {
            ru: "Каталог инструментов",
            en: "Tools catalogue",
          },
          meta: pages.all_tools,
          link: "/all_tools",
        },
        {
          name: {
            ru: "Каталог приспособлений",
            en: "Fixtures catalogue",
          },
          meta: pages.fixture_catalogue,
          link: "/fixture_catalogue",
        },
        {
          name: {
            ru: "Каталог СИ",
            en: "Measure tools catalogue",
          },
          meta: pages.all_measure_tools_catalogue,
          link: "/all_measure_tools_catalogue",
        },
        {
          name: {
            ru: "Каталог станков",
            en: "Machine tools catalogue",
          },
          meta: pages.all_machine_tools,
          link: "/all_machine_tools",
        },
        {
          name: {
            ru: "Материалы",
            en: "Materials",
          },
          meta: pages.all_materials,
          link: "/all_materials",
        },
        {
          name: {
            ru: "Стандарты",
            en: "Standards",
          },
          meta: pages.exp_standards,
          link: "/exp_standards",
        },
        {
          name: {
            ru: "Типы станков",
            en: "Machine tool types",
          },
          meta: pages.mt_types,
          link: "/mt_types",
        },
        {
          name: {
            ru: "Рабочие органы станка",
            en: "Machine tool working bodies",
          },
          meta: pages.mt_working_bodies,
          link: "/mt_working_bodies",
        },
        {
          name: {
            ru: "МТО",
            en: "МТО",
          },
          meta: pages.mto,
          link: "/mto",
        },
        {
          name: {
            ru: "КТЭ",
            en: "КТЭ",
          },
          meta: pages.kte,
          link: "/kte",
        },
        {
          name: {
            ru: "ЭСБ",
            en: "ЭСБ",
          },
          meta: pages.esb,
          link: "/esb",
        },
        {
          name: {
            ru: "Типы СИ",
            en: "Measuring tool types",
          },
          meta: pages.si_types,
          link: "/measuring-tool-types",
        },
        {
          name: {
            ru: "Типы заготовок",
            en: "Blank types",
          },
          meta: pages.blank_types,
          link: "/blank_types",
        },
        {
          name: {
            ru: "Типы инструментов",
            en: "Tool types",
          },
          meta: pages.t_nomenclature,
          link: "/t_nomenclature",
        },
      ],
    },
    {
      name: {
        ru: "Администрирование",
        en: "Administration",
      },
      meta: {
        auth: true,
        prod: false,
        user: false,
        manufacturer: false,
        admin: true,
        expert: false,
      },
      icon: "admin",
      open: false,
      children: [
        {
          name: {
            ru: "Пользователи",
            en: "Users",
          },
          meta: pages.all_users,
          link: "/all_users",
        },
        {
          name: {
            ru: "Организации",
            en: "Organizations",
          },
          meta: pages.all_organizations,
          link: "/all_organizations",
        },
        {
          name: {
            ru: "Заявки и заказы",
            en: "Applications and orders",
          },
          meta: pages.all_orders,
          link: "/all_orders",
        },
        {
          name: {
            ru: "Аналитика",
            en: "Analytics",
          },
          meta: pages.analytics,
          link: "/admin_analytics",
        },
        {
          name: {
            ru: "Платежи",
            en: "Payments",
          },
          meta: pages.admin_payments,
          link: "/admin_payments",
        },
      ],
    },
  ],
});

const mutations = {
  setLanguage(state, lang) {
    state.language = lang;
  },
  setCurrency(state, curr) {
    state.currency = curr;
  },
  setScroll(state, scroll) {
    state.scrollTop = scroll;
  },
  setWidth(state, width) {
    state.width = width;
  },
  setHeight(state, height) {
    state.height = height;
  },
  setLoading(state, loading) {
    state.pageLoading = loading;
  },
  setShowHeader(state, data) {
    state.showHeader = data;
  },
  setShow404(state, data) {
    state.show404 = data;
  },
  setOpenMenu(state, data) {
    const result = state.menu.slice();
    result[data.index].open = data.value;
    state.menu = result;
  },
  closeAllMenu(state) {
    const result = state.menu.slice();
    for (const item of result) {
      item.open = false;
    }
    state.menu = result;
  },
};

const getters = {};

const actions = {
  getUser() {
    query(
      "get",
      "getUserInfo",
      {},
      {
        token: localStorage.getItem("token"),
      },
    ).then((user) => {
      console.log(user);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
