var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Modal',{attrs:{"show":_vm.showEditModal,"title":_vm.text.edit_title[_vm.language],"ok-text":_vm.text.save},on:{"ok":_vm.editShop,"cancel":function($event){return _vm.setShowEditModal(false)}}},[_c('template',{slot:"body"},[_c('div',{staticStyle:{"padding":"20px"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('Input',{attrs:{"default-value":_vm.row.name ? _vm.row.name : '',"label":_vm.text.name[_vm.language]},on:{"change":function($event){return _vm.saveEditData($event, 'name')}}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('Select',{attrs:{"values":_vm.shopTypes,"dropdown-props":{
              label: 'label',
              value: 'value',
            },"position-type":'fixed',"default-value":_vm.row.shop_type ? _vm.row.shop_type : '',"label":_vm.text.shop_type[_vm.language]},on:{"change":function($event){return _vm.saveEditData($event.value, 'id_processing_method')}}})],1),_c('div',{staticStyle:{"width":"100%"}},[(_vm.countries && _vm.countries[_vm.editCountryIndex])?_c('Select',{attrs:{"position-type":'fixed',"values":_vm.countries,"dropdown-props":{
              label: 'name',
              value: 'id',
            },"default-value":_vm.countries[_vm.editCountryIndex].name,"label":_vm.text.country[_vm.language]},on:{"change":function($event){return _vm.changeCountry($event.value)}}}):_vm._e()],1),_c('div',{staticStyle:{"width":"100%"}},[_c('Select',{attrs:{"position-type":'fixed',"values":_vm.regions,"dropdown-props":{
              label: 'name',
              value: 'id',
            },"default-value":_vm.row.address ? _vm.row.address : '',"label":_vm.text.place[_vm.language]},on:{"change":function($event){return _vm.saveEditData($event.value, 'id_address_region')}}})],1)])])],2),(_vm.showDeleteModal)?_c('Modal',{attrs:{"show":_vm.showDeleteModal,"title":_vm.text.delete_title[_vm.language],"ok-text":_vm.text.delete},on:{"ok":_vm.deleteWorkshop,"cancel":function($event){return _vm.setShowDeleteModal(false)}}},[_c('template',{slot:"body"},[(_vm.row && _vm.row.name)?_c('div',{staticStyle:{"padding":"20px"}},[_c('span',[_vm._v(_vm._s(_vm.text.delete_text[_vm.language])+": "),_c('b',[_vm._v(_vm._s(_vm.row.name))]),_vm._v(" ?")])]):_vm._e()])],2):_vm._e(),_c('div',{staticClass:"page__edit_large-title page__edit_margin"},[_vm._v(" "+_vm._s(_vm.text.large_title[_vm.language])+" ")]),_c('Button',{staticClass:"page__edit_margin",attrs:{"text":_vm.text.edit[_vm.language],"block":""},on:{"click":function($event){return _vm.setShowEditModal(true)}}}),_c('Button',{staticClass:"page__edit_margin",attrs:{"text":_vm.text.delete[_vm.language],"block":""},on:{"click":function($event){return _vm.setShowDeleteModal(true)}}}),_c('div',{staticClass:"page__edit_title"},[_vm._v(_vm._s(_vm.text.name[_vm.language])+":")]),_c('div',{staticClass:"page__edit_margin page__edit_value"},[_vm._v(" "+_vm._s(_vm.row.name)+" ")]),_c('div',{staticClass:"page__edit_title"},[_vm._v(_vm._s(_vm.text.place[_vm.language])+":")]),_c('div',{staticClass:"page__edit_margin page__edit_value"},[_vm._v(" "+_vm._s(_vm.row.address)+" ")]),_c('div',{staticClass:"page__edit_title"},[_vm._v(_vm._s(_vm.text.shop_type[_vm.language])+":")]),_c('div',{staticClass:"page__edit_margin page__edit_value"},[_vm._v(" "+_vm._s(_vm.row.shop_type)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }